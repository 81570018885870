#members-modal {
  .modal-content {
    border-radius: 0;
    .modal-header {
      color: var(--bs-secondary);
      font-size: 0.875rem;
      font-weight: 700;
    }
    .modal-body {
      padding: 2rem 4rem;
      .members-selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .members-modal-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .members-modal-btn {
          margin-top: 2rem;
          height: 2.25rem;
          width: 9rem;
          font-size: 0.75rem;
          &:disabled {
            border: none;
            color: var(--bs-font-color);
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #members-modal {
    width: 100%;
    margin: auto;
    .modal-content {
      border-radius: 0;
      .modal-header {
        border: none;
      }
      .modal-body {
        padding: 2rem;
        .group-modal-title {
          font-size: 1rem;
          font-weight: 600;
          margin: 0 0 2.5rem 0;
        }
        .group-modal-selected {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-weight: 600;
          font-size: 0.875rem;
          margin-top: 3rem;
          .selected-group-label {
            color: var(--bs-gray-4);
          }
          .selected-group-name {
            color: var(--bs-secondary);
            font-size: 1rem;
          }
        }
        .group-modal-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3rem;
          .group-modal-btn {
            height: 2.25rem;
            width: 9rem;
            border-radius: 0;
            font-size: 0.75rem;
            font-weight: 700;
            +.group-modal-btn {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #members-modal {
    margin: 0;
    position: absolute;
    left: 0;
  }
}