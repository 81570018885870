#signinPage {
  .signin-container {
    padding-top: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 42px;
    align-items: center;
    .signin-image {
      width: 326px;
    }
  }
  .signin-button {
    width: 326px;
    height: 3.25rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    background-color: var(--bs-secondary-3);
  }
}

@media (max-width: 1400px) {
  #signinPage .signin-container {
    padding-top: 35vh;
  }
}

@media (max-width: 900px) {
  #signinPage {
    .signin-container .signin-image {
      transform: scale(0.85);
    }
    .signin-button {
      font-size: 0.875rem;
      width: 277px;
      height: 2.75rem;
    }
  }
}

@media (max-width: 480px) {
  #signinPage .signin-container {
    .signin-button {
      width: 260px;
      height: 2.5rem;
    }
    .signin-image {
      transform: scale(0.8);
    }
  }
}