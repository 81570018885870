.page-layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  .page-container {
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: thin; 
    scrollbar-color: rgba(131, 131, 131, 0.428) transparent;
    transition: margin 0.2s;
    &.sidebar-state-expanded {
      margin-left: var(--sidebar-width);
    }
    &.sidebar-state-collapsed {
      margin-left: var(--sidebar-width-collapsed);
    }
    &.sidebar-state-hidden {
      margin-left: 0px;
    }
    .page-content {
      height: 99%;
      margin: 0 4rem;
      margin-bottom: 1rem;
    }
  }
}

#select-client-modal .modal-header {
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--bs-secondary);
}

#layoutSpinner {
  text-align: center;
  padding-top: 200px;
}

@media (max-width: 1400px) {
  .page-layout .page-container .page-content {
    padding: 1rem;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .page-layout .page-container {
    overflow-x: hidden;
    margin-left: 0;
    &.sidebar-state-expanded {
      margin-left: 0;
    }
    &.sidebar-state-collapsed {
      margin-left: 0;
    }
  }
  .notification .notification-toast {
    margin-left: 0;
    &.sidebar-state-expanded {
      margin-left: 0;
    }
    &.sidebar-state-collapsed {
      margin-left: 0;
    }
  }
}

@media (max-width: 480px) {
  .page-layout .page-container .page-content {
    padding: 0.5rem;
    height: 100%;
  }
}