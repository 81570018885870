.accordion-button {
  &:hover {
    background-color: $hover-bg;
  }
  &:not(.collapsed) {
    color: $accordion-button-active-color;
    background-color: $accordion-button-active-bg;

    &::before {
      background-image: escape-svg($accordion-button-active-icon);
      transform: $accordion-icon-transform;
    }
    &::after {
      content: none;
    }
  }

  &::before {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: 0;
    margin-right: 14px;
    content: "";
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }
  &::after {
    content: none !important;
  }
}