#activeSyncButton, #syncButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
  border-radius: 50%;
}

#activeSyncButton {
  background-color: var(--bs-primary-2);
}