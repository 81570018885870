.notification-toast {
  bottom: 30px;
  position: relative;
  &.sidebar-state-expanded {
    margin-left: var(--sidebar-width);
  }
  &.sidebar-state-collapsed {
    margin-left: var(--sidebar-width-collapsed);
  }
  &.sidebar-state-hidden {
    margin-left: 0px;
  }
  .toast-header {
    justify-content: space-between;
  }
  .toast-message {
    color: black;
    font-weight: 600;
  }
}