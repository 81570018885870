#editClientModal {
  max-width: none;
  width: 60rem;
  .modal-content {
    border-radius: 0;
    padding: 2.5rem;
  }
  #modalSpinner {
    text-align: center;
  }
  .modal-body {
    overflow-y: scroll;
    scrollbar-width: thin; 
    scrollbar-color: rgba(131, 131, 131, 0.428) transparent;
    overflow-x: hidden;
    padding-right: 2rem;
  }
  .modal-header {
    display: flex;
    align-items: center;
  }
  .modal-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--bs-secondary);
  }
  .edit-client-fields {
    margin: 1.5rem 0;
  }
  .form-subtitle {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--bs-gray-2);
  }
  .form-input {
    font-size: 0.875rem;
    font-weight: 300;
  }
  .form-button {
    margin-top: 3rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 1.5rem;
      width: 20%;
      font-size: 0.75rem;
    }
  }
  .existing-client-admins {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 0 2rem;
  }
  .client-admin-icon {
    height: 32px;
    width: 32px
  }
  .client-admin-empty {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 1rem 2rem;
  }
  .add-new-client-btn {
    margin: 0.75rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: none;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .delete-admin-icon:hover {
    color: var(--bs-danger);
  }
  .edit-admin-icon:hover {
    color: var(--bs-primary);
  }
  .confirm-admin-icon:hover {
    color: var(--bs-success);
  }
  .cancel-admin-icon:hover {
    color: var(--bs-danger);
  }
}

@media (max-width: 900px) {
  #editClientModal {
    width: 95%;
    margin: 0;
    position: absolute;
    left: 2.5%;
    right: 2.5%;
    top: 2%;
    bottom: 2%;
    .modal-body {
      padding: 0.5rem;
    }
    .modal-content {
      width: 100%;
      padding: 1rem;
    }
    .modal-title {
      font-size: 1rem;
    }
    #editClientForm {
      margin-top: 1rem;
    }
    .form-button {
      margin: 1.5rem 0;
      justify-content: center;
      button {
        padding: 1.5rem 0;
        width: 10rem;
      }
    }
    .existing-client-admins {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.75rem;
      margin: 0 0.75rem;
    }
    .client-admin-icon {
      height: 24px;
      width: 24px
    }
    .add-new-client-btn {
      margin: 0.25rem 0.75rem;
      width: 75%;
    }
    .form-button {
      margin: 2.5rem 0.5rem;
      button {
        padding: 1.2rem 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  #editClientModal {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .modal-content {
      padding: 0.5rem;
      .modal-body {
        padding: 0.25rem;
      }
    }
    .form-button {
      margin: 1.5rem 0.5rem;
    }
  }
}