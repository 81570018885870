a.link {
  text-decoration: none;
  width: 100%;
  .nav-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 15px;
    align-items: center;
  }
  .nav-button.type-main {
    background-color: transparent;
    border: 0;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    svg {
      width: 1.2em;
      height: 1.2em;
    }
    &:hover:not(:active):not(:focus):not(.active) {
      color: var(--bs-secondary-5);
      background-color: transparent;
    }
    &:active, &:focus, &.active {
      background-color: var(--bs-secondary-2);
      box-shadow: none;
      border-radius: 4px;
    }
    .nav-button-text {
      display: none;
    }
    .nav-button-icon {
      fill: currentColor;
    }
  }
}

@media (max-width: 900px) {
  a.link {
    .nav-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-align: left;
      gap: 15px;
      align-items: center;
    }
    .nav-button.type-main {
      background-color: transparent;
      border: 0;
      padding-left: 0px;
      padding-right: 0px;
      justify-content: center;
      svg {
        width: 1.2em;
        height: 1.2em;
      }
      &:hover:not(:active):not(:focus):not(.active) {
        color: var(--bs-secondary-5);
        background-color: transparent;
      }
      &:active, &:focus, &.active {
        background-color: var(--bs-secondary-2);
        box-shadow: none;
        border-radius: 4px;
      }
      .nav-button-text {
        display: flex;
        font-weight: 500;
      }
    }
  }
}