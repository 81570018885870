#groupSettings .create-group-container {
  .form-switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    input {
      cursor: pointer;
    }
  }

  .text-input-title h5 {
    font-size: 1rem;
  }

  .reports-and-users .form-label.title {
    width: 100%;
    border-bottom: 1px solid var(--bs-gray-3);
    h5 {
      color: var(--bs-gray-2);
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
    }
  }

  .select-options {
    max-height: 175px;
    min-height: 75px;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 40px;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
    margin-top: 3rem;
    button {
      width: 7rem;
    }
    button.save-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
    }
  }
}