.edubi {
  @import 'bootstrap/scss/functions';
  @import 'bootstrap/scss/mixins';
  @import 'bootstrap/scss/variables';
  @import './variables.scss';

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('../../../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  }

  --bs-body-color: #{$body-color};
  --bs-body-color-inv: #{$body-color-inv};
  --bs-body-font-family: #{$font-family-base};
  --bs-body-bg: #{$body-bg};
  --bs-body-bg-light: #{$body-bg-light};
  --bs-hover-bg: #{$hover-bg};
  --bs-box-shadow-color: #{$box-shadow-color};

  --sidebar-width: #{$sidebar-width};
  --sidebar-width-collapsed: #{$sidebar-width-collapsed};

  @each $color, $value in $theme-colors {
    --bs-#{$color}: #{$value};
  }

  /* Components */
  @import './components/accordion.scss';
  @import './components/tabs.scss';
  @import './components/button.scss';
  @import './components/searchBox.scss';

  @import 'bootstrap/scss/bootstrap';

  /* Dropdown toggle focus and active styling */
  @each $color, $value in $theme-colors {
    .show > .btn-#{$color}.dropdown-toggle {
      @include button-variant($value, $value);
    }
  }
}
