#reportSettings {
  padding-top: 1rem;
  .report-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .report-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    .left-container {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    .right-container {
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }
  .title-label {
    font-size: 0.875rem;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: var(--bs-gray-2);
    border-bottom: 1px solid var(--bs-gray-2);
  }
  .powerbi-name > .accordion-button {
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 1rem;
    &:hover, &:not(.collapsed) {
      color: var(--bs-body-bg);
      background-color: var(--bs-secondary);
    }
  }
  .report-select-btn {
    position: relative;
    background-color: transparent;
    border: none;
    width: 100%;
    min-height: 38px;
    color: var(--bs-gray-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: normal;
    padding-left: 2rem;
    padding-right: 0;
    &:hover, &:active, &:focus, &.active {
      color: var(--bs-secondary);
      font-weight: 600;
    }
    .report-select-btn-name {
      text-align: left;
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
    }
    svg {
      flex: none;
    }
  }
  .warning {
    color: var(--bs-danger);
    position: absolute;
    left: 0;
  }
  .report-settings-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 1rem;
  }
  .form-label {
    margin: 0;
  }
  .report-form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    .form-select {
      font-size: 0.875rem;
      width: 15rem;
      padding: 0.25rem 2rem 0.25rem 0.5rem;
    }
    .report-guardian-years {
      display: flex;
      .report-guardian-year {
        display: flex;
        align-items: center;
        .form-check-input {
          margin: 0 0.25rem;
          background-color: var(--bs-gray-3);
          cursor: pointer;
          &:focus {
            box-shadow: none;
          }
          &:checked {
            background-color: var(--bs-secondary);
          }
        }
        +.report-guardian-year {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .report-roles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.25rem 0;
    padding-left: 0.5rem;
    box-sizing: border-box;
  }
  .report-roles + .report-roles {
    margin-top: 0.25rem 0;
    border-top: 1px solid var(--bs-gray-3);
  }
  .report-role {
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 0.5rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .add-role-btn {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0.25rem 0;
  }
  .add-role-input {
    width: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }
  .form-label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1.5rem;
    padding: 1.25rem;
    font-size: 0.75rem;
    width: 12.5rem;
  }
}

@media (max-width: 900px) {
  #reportSettings {
    padding-top: 0.5rem;
    .report-content {
      flex-direction: column;
      margin-top: 1.5rem;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 100%;
        margin-top: 1rem;
      }
    }
    .powerbi-name > .accordion-button, .accordion-button {
      font-size: 0.875rem;
      padding: 0.75rem;
    }
    .accordion-body {
      padding: 0.5rem;
      width: 100%;
    }
    .report-select-btn {
      padding-left: 1.5rem;
      .report-select-btn-name {
        font-size: 0.75rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
      }
    }
    .report-settings-container {
      padding: 1rem 0.5rem;
    }
  }
}

@media (max-width: 480px) {
  #reportSettings {
    .report-form-row {
      flex-direction: column;
      align-items: flex-start;
      .form-label {
        margin-bottom: 0.5rem;
      }
      .form-select {
        width: 100%;
      }
      .report-guardian-years {
        width: 100%;
        justify-content: space-evenly;
      }
    }
    #report-guardian-switch {
      flex-direction: row;
      align-items: center;
      .form-label {
        margin: 0;
      }
    }
  }
}