$primary: #a77a46;
$secondary: #C5C5C5;
$success: #198754;
$dark: rgb(40, 40, 40);
$info: #0a839b;
$warning: #b38600;
$danger: #90222d;
$light: #bfbfbf;

// TODO: Change tint and shade to shift and add a variable which is -1 or +1 to indicate the contrast
$body-color: #f1f1f1;
$body-bg: shade-color($primary, 90%);
$component-active-color: #eef0f0;
$component-active-bg: rgba(255, 255, 255, 0.1);
$hover-bg: rgba(255, 255, 255, 0.1);
$box-shadow-color: shade-color($body-bg, 70%);

// Forms and inputs
$input-bg: tint-color($primary, 90%);
$input-color: shade-color($primary, 80%);
$form-check-input-checked-bg-color: shade-color($primary, 30%);
$input-focus-border-color: shade-color($component-active-bg, 50%);
$input-disabled-bg: shade-color($input-bg, 30%);
$input-disabled-border-color: shade-color($input-bg, 50%);

// Dropdowns
$dropdown-bg: tint-color($primary, 70%);
$dropdown-link-hover-bg: tint-color($primary, 50%);
$dropdown-link-active-color: shade-color($component-active-color, 50%);
$dropdown-link-active-bg: tint-color($primary, 50%);

// Tabs and Nav Links
$nav-tabs-link-active-color: tint-color($primary, 90%);
$nav-link-color: tint-color($primary, 20%);

// Accordion
$accordion-button-active-bg: $component-active-bg;
$accordion-button-active-color: $component-active-color;
$accordion-border-width: 0;
$accordion-border-color: transparent;
$accordion-button-focus-box-shadow: 0 0 0 0 transparent;

// List Group
$list-group-bg: tint-color($primary, 70%);
$list-group-hover-bg: tint-color($primary, 50%);
$list-group-active-color: shade-color($component-active-color, 50%);
$list-group-active-bg: shade-color($primary, 50%);
$list-group-action-active-color: shade-color($component-active-color, 50%);;
$list-group-action-active-bg: tint-color($primary, 50%);
$list-group-action-hover-color: shade-color($component-active-color, 90%);

// Buttons
$btn-disabled-opacity: 0.3;

// Close icon
$btn-close-color: #FFFFFF;

// Alert
$alert-bg-scale: -30%;
$alert-color-scale: 50%;
$alert-border-scale: $alert-color-scale;

// Modals
$modal-content-color: $body-color;
$modal-content-bg: tint-color($body-bg, 20%);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'dark': $dark,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
);
