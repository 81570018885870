.custom-accordion-button.btn-outline-primary.btn {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  overflow-wrap: anywhere;
  text-align: left;
  margin-left: 15px;
  margin-right: 0px;
  color: var(--bs-body-color-inv);
  opacity: 0.5;
  padding-right: 10px;
  padding-left: 20px;
  border-radius: 4px;
  &:hover:not(.active) {
    color: var(--bs-secondary-5);
    background-color: transparent;
    opacity: 1;
  }
  &.active {
    opacity: 1;
    font-weight: 700;
    background-color: var(--bs-secondary-2);
    box-shadow: none;
    border-radius: 4px;
  }
}