.sidebar-background {
  display: none;
}
#sidebar {
  transition: width 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 0.75rem 0.4rem;
  width: var(--sidebar-width-collapsed);
  min-width: 40px;
  background-color: var(--bs-primary);
  text-align: center;
  .select-client-btn {
    height: fit-content;
    width: 100%;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-bottom: 1.5rem;
    svg {
      width: 1.2em;
      height: 1.2em;
    }
    .select-client-text {
      display: none;
    }
    &:hover:not(:active):not(:focus):not(.active) {
      color: var(--bs-secondary-5);
      background-color: transparent;
    }
  }
  #sidebarContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    #sidebarTop {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
    .group-accordion {
      display: flex;
      flex-direction: column;
      gap: 10px; 
    }
    .signout-btn {
      button {
        padding: 0;
        background-color: transparent;
        width: 100%;
        &:hover {
          color: var(--bs-secondary-5);
        }
      }
      .signout-mobile-text {
        display: none;
      }
    }
  }
  .user-groups-container {
    margin: 1.5rem 0;
  }
}

@media (max-width: 900px) {
  #sidebar-background.open {
    display: flex;
    z-index: 98;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  #sidebar {
    max-width: none;
    position: absolute;
    width: 100%;
    height: fit-content;
    padding: 0;
    z-index: 99;
    margin-top: 104px;
    right: 0;
    animation-name: rightToLeft;
    animation-duration: 150ms;
    .select-client-btn {
      margin-top: 0.25rem;
      height: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      text-align: left;
      gap: 15px;
      align-items: center;
      .select-client-text {
        display: flex;
        font-weight: 500;
      }
    }
    #sidebarContent {
      padding: 0;
      #sidebarTop {
        gap: 0px;
        background-color: var(--bs-primary);
      }
      button {
        justify-content: flex-start;
        padding: 1.5rem 2.5rem;
      }
      .group-accordion {
        gap: 0px; 
      }
      .signout-btn {
        width: 100%;
        margin-bottom: 0.25rem;
        background-color: var(--bs-primary);
        button {
          gap: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 1.5rem 2.5rem;
        }
        .signout-mobile-text {
          display: flex;
          font-weight: 500;
        }
      }
    }
  }
  #sidebar.hidden {
    display: none;
  }
  @keyframes rightToLeft {
    0% {
      left: 100vw;
    }
    100% {
      left: 0;
    }
  }
}

@media (max-width: 480px) {
  #sidebar {
    margin-top: 96px;
    #sidebarContent {
      button {
        padding: 1.3rem 2rem;
      }
      .signout-btn {
        button {
          padding: 1.3rem 2rem;
        } 
      }
    } 
    #tooltip {
      display: none;
    }
  }
}
