@import './EditClientModal/EditClientModal.scss';
@import './AddNewClientModal/AddNewClientModal.scss';

#editClients {
  padding-top: 1rem;
  .clients-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .list-group {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 1.5rem;
    gap: 1rem;
    .client-item {
      border-radius: 0;
      background-color: inherit;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 20%;
      min-width: 12.5rem;
      margin-bottom: 0.5rem;
      .client-buttons {
        border-radius: 3px;
        font-size: 0.75rem;
        padding: 0.5rem 0;
        width: 7.5rem;
        +.client-buttons {
          margin-top: 0.2rem;
        }
      }
    }
    .client-icon {
      background-color: var(--bs-gray);
    }
    .client-name {
      color: var(--bs-primary);
      font-weight: 600;
      font-size: 0.875rem;
    }
    .client-code {
      color: var(--bs-gray-2);
      font-size: 0.875rem;
    }
  }
  .new-client-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5rem;
    padding: 1.25rem;
    font-size: 0.75rem;
    width: 10rem;
  }
}

@media (max-width: 14000px) {
  #editClients .list-group .client-item {
    width: 20%;
    max-width: 12.5rem;
  }
}

@media (max-width: 900px) {
  #editClients {
    padding-top: 0.5rem;
    .list-group .client-item {
      width: 50%;
      min-width: 8rem;
      max-width: 10rem;
    }
    .new-client-btn {
      margin-top: 3rem;
    }
  }
}