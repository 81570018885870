.idle-modal {
  .modal-dialog .modal-footer .btn {
    height: 38px;
    &:disabled {
      color: var(--bs-gray-3);
      background-color: var(--bs-secondary);
    }
  }
}

@media (max-width: 900px) {
  .idle-modal {
    width: 100%;
    .modal-dialog {
      margin: 1rem auto;
      max-width: 30rem;
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .idle-modal .modal-dialog {
    margin: 1rem 0.25rem;
    .modal-footer {
      flex-direction: column;
      justify-content: space-evenly;
      button {
        width: 13rem;
        font-size: 0.875rem;
        font-weight: 600;
      }
    }
  }
}