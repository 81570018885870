/* ------------------------ CUSTOM VARIABLES ------------------------ */

$primary: #253551;
$primary-rgb: rgb(37, 53, 81);
$primary-2: #039BCC;
$secondary: #10437A;
$secondary-rgb: rgb(16, 67, 122);
$secondary-2: #185FA1;
$secondary-3: #2492EB;
$secondary-4: #70B6F4;
$secondary-5: #A1D1F9;
$secondary-6: #E9F2FA;
$tertiary: #82CCD6;
$tertiary-2: #53C6CC;
$tertiary-3: #3A919E;
$gray: #EFEFEF;
$gray-1: #F6F6F6;
$gray-2: #ACACAC;
$gray-3: #D9D9D9;
$gray-4: #7f7f7f;
$success: #198754;
$dark: rgb(15, 12, 56);
$info: #0a839b;
$warning: #b38600;
$danger: #90222d;
$light: #adb5bd;

$body-color: #000000;
$body-color-inv: #FFFFFF;
$body-bg: #F5F5F5;
$body-bg-light: #FFFFFF;
$component-active-color: rgb(88, 101, 105);
$component-active-bg: rgb(158, 184, 191);
$hover-bg: tint-color($primary, 10%);
$box-shadow-color: shade-color($body-bg, 70%);

$sidebar-width: 250px;
$sidebar-width-collapsed: 40px;

/* ------------------------ BOOTSTRAP VARIABLES ------------------------ */

// Forms and inputs
$input-bg: $gray-1;
$input-color: shade-color($input-bg, 60%);
$input-focus-border-color: $secondary;
$input-focus-box-shadow: 0px 0 7px 3px $secondary;
$input-disabled-bg: shade-color($input-bg, 30%);
$input-disabled-border-color: shade-color($input-bg, 50%);
$input-border-width: 0px;
$input-border-radius: 2px;

// Check input
$form-check-input-checked-color: $secondary;
$form-check-input-checked-bg-color: rgba($secondary-2, 0.2);
$form-check-input-bg: $gray;
$form-switch-color: $gray-2;
$form-check-input-border: none;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-color}'/></svg>");
$form-switch-checked-color: $secondary;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-checked-color}'/></svg>");
$form-check-input-active-filter: null;

// Dropdowns
$dropdown-bg: tint-color($primary, 70%);
$dropdown-link-hover-bg: tint-color($primary, 50%);
$dropdown-link-active-color: shade-color($component-active-color, 50%);
$dropdown-link-active-bg: tint-color($primary, 50%);

// Accordion
$accordion-border-width: 0;
$accordion-icon-color: $gray-2;
$accordion-icon-active-color: $secondary-5;
$accordion-border-color: transparent;
$accordion-button-focus-box-shadow: 0 0 0 0 transparent;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// List Group
$list-group-bg: tint-color($primary, 70%);
$list-group-hover-bg: tint-color($primary, 50%);
$list-group-active-color: shade-color($component-active-color, 50%);
$list-group-active-bg: shade-color($primary, 50%);
$list-group-action-active-color: shade-color($component-active-color, 50%);;
$list-group-action-active-bg: tint-color($primary, 50%);
$list-group-action-hover-color: shade-color($component-active-color, 90%);

// Close icon
$btn-close-color: #FFFFFF;

// Modals
$modal-content-color: $body-color;
$modal-content-bg: tint-color($body-bg, 20%);

$theme-colors: (
  'primary': $primary,
  'primary-rgb': $primary-rgb,
  'primary-2': $primary-2,
  'secondary': $secondary,
  'secondary-rgb': $secondary-rgb,
  'secondary-2': $secondary-2,
  'secondary-3': $secondary-3,
  'secondary-4': $secondary-4,
  'secondary-5': $secondary-5,
  'secondary-6': $secondary-6,
  'tertiary': $tertiary,
  'tertiary-2': $tertiary-2,
  'tertiary-3': $tertiary-3,
  'gray': $gray,
  'gray-1': $gray-1,
  'gray-2': $gray-2,
  'gray-3': $gray-3,
  'gray-4': $gray-4,
  'success': $success,
  'dark': $dark,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
);
