#homepage {
  overflow-x: hidden;
  margin-left: 69px;
  margin-right: 78px;
  padding-bottom: 0.25rem;
  .welcome-banner {
    padding: 2rem;
    height: fit-content;
    background-color: var(--bs-secondary-6);
    position: relative;
    border-radius: 4px;
    margin-bottom: 4rem;
    color: var(--bs-secondary);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: 500ms;
    .client-title{
      padding: 0 0.5rem;
      font-size: 2rem;
      font-weight: 600;
    }
    .client-description {
      font-size: 0.875rem;
      font-weight: 500;
      width: 65%;
      overflow-wrap: break-word;
      hyphens: auto;
    }
    .analytics-illustration {
      position: absolute;
      left: 70%;
      top: 20%;
    }
  }
  .report-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.75rem;
  }
  .home-section {
    padding: 0.75rem 0.25rem;
    +.home-section {
      margin-top: 0.75rem;
    }
  }
  .section-name {
    color: var(--bs-secondary);
    font-weight: 600;
    font-size: 1.5rem;
  }
}

@media (max-width: 1400px) {
  #homepage {
    margin: 0 1rem;
    .welcome-banner {
      padding: 1.75rem;
      .client-title {
        font-size: 1.875rem;
      }
      .client-description {
        font-size: 0.825rem;
      }
    }
    .section-name {
      margin-left: 1rem;
    }
    .report-tiles {
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      .tile-outer-container {
        width: 30%;
      }
    }
  }
}

@media (max-width: 900px) {
  #homepage {
    margin: 0;
    .welcome-banner {
      margin: 0;
      margin-bottom: 5rem;
      padding: 1.25rem;
      .client-title{
        font-size: 1.75rem;
        padding: 0 1rem;
      }
      .client-description {
        width: 90%;
        padding: 0 0.5rem;
      }
      .analytics-illustration {
        transform: scale(0.8);
        left: 60%;
        top: 65%;
      }
    }
    .report-tiles {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .tile-outer-container {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  #homepage .welcome-banner {
    padding: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;
    .client-title {
      padding: 0 0.2rem;
      font-size: 1.625rem;
    }
    .client-description {
      padding: 0;
      width: 100%;
    }
    .analytics-illustration {
      display: none;
      transform: scale(0.7);
      left: 35%;
      top: 80%;
    }
  } 
}