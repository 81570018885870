#serverError {
  margin-top: 150px;
  text-align: center;
}

@media (max-width: 480px) {
  #serverError h5 {
    width: 90%;
    margin: 2rem auto;
  }
}