#globalAdmin {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 1rem;
  .ga-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    .user-role {
      font-size: 0.7rem;
      font-weight: 800;
      color: var(--bs-secondary);
      margin-right: 1.5rem;
    }
    .user-email {
      font-size: 0.875rem;
    }
    +.user {
      margin-top: 0.2rem;
    }
  }
  .sync-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5rem;
    padding: 1.25rem;
    font-size: 0.75rem;
    width: 12.5rem;
    &:disabled {
      background-color: var(--bs-secondary);
    }
  }
}