#expiredSession {
  margin-top: 100px;
  text-align: center;
  .sign-out {
    margin-top: 50px;
  }
  .expired-signout {
    width: 9.75rem;
    height: 2.375rem;
    font-size: 0.75rem;
  }
}