.client-guardians-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.75rem 0;
  padding: 0 0.5rem;
  .form-switch {
    width: fit-content;
    padding: 0;
    margin-right: 0.25rem;
  }
  .client-guardians-label {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.25rem;
    width: fit-content;
    margin: 0;
  }
}
.client-guardians-group {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  +.client-guardians-group {
    margin-top: 0.5rem;
  }
  .guardian-year-select {
    width: fit-content;
    font-size: 0.875rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 0.75rem;
  }
  .client-guardians-label {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.25rem;
    width: fit-content;
    margin: 0;
  }
  .client-guardians-input {
    font-size: 0.875rem;
    font-weight: 300;
    width: 30rem;
    text-align: end;
    border-bottom: 1px solid var(--bs-secondary);
  }
}
#guardian-year-selects {
  flex-wrap: wrap;
  justify-content: space-between;
  .client-guardians-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12rem;
    .year-select-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.5rem;
      label {
        margin-bottom: 0;
      }
      .form-switch {
        width: fit-content;
        padding: 0;
        margin-right: 0.25rem;
      }
    }
  }
  .guardian-year-select {
    width: fit-content;
    font-size: 0.875rem;
    margin-right: 0;
  }
}

@media (max-width: 900px) {
  .client-guardians-switch {
    padding: 0 0.25rem;
    .form-switch .form-check-input {
      margin-left: 0;
    }
  }
  .client-guardians-group {
    min-width: 20rem;
    padding: 0 0.25rem;
    .guardian-year-select {
      margin-right: 0.25rem;
    }
    .client-guardians-input {
      width: 70%;
      padding: 5px;
    }
  }
  #guardian-year-selects {
    padding-right: 0.25rem;
    .client-guardians-section {
      margin-bottom: 0.5rem;
      margin: 0.5rem auto;
    }
    .year-select-title .form-switch .form-check-input {
      margin-left: 0;
    }
  }
}

@media (max-width: 480px) {
  .client-guardians-switch {
    padding: 0;
    .form-switch .form-check-input {
      margin-right: 1rem;
    }
  }
  .client-guardians-group {
    min-width: 0;
    padding: 0;
    .guardian-year-select {
      padding-right: 2rem;
      margin-right: 1.25rem;
    }
    .client-guardians-input {
      width: 98%;
      margin: auto;
    }
  }
  .group-inputs {
    flex-direction: column;
    align-items: flex-start;
  }
}
