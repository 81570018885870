#adminReportSettings {
  padding-top: 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .report-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .report-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    .left-container {
      width: 40%;
      display: flex;
      flex-direction: column;
      height: 65vh;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin; 
      scrollbar-color: rgba(131, 131, 131, 0.428) transparent;
      .new-section-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        input {
          width: 100%;
          padding: 0.15rem 0.5rem;
          margin-right: 0.25rem;
          font-size: 0.9rem;
        }
        button {
          font-size: 0.75rem;
          width: 4rem;
          height: 2rem;
          + button {
            margin-left: 0.25rem;
          }
        }
        .add-section-btn {
          width: 100%;
        }
      }
      .react-grid-layout {
        position: relative;
        transition: height 200ms ease;
        width: 98%;
        .section-grid-container {
          max-width: 100%;
          .react-grid-layout {
            position: relative;
            .react-grid-item {
              transition: all 200ms ease;
              transition-property: left, top;
            }
            .react-resizable-handle {
              display: none;
            }
            .react-grid-item.cssTransforms {
              transition-property: transform;
            }
            .react-grid-item.react-draggable-dragging {
              transition: none;
              z-index: 3;
            }
            .react-grid-item.react-grid-placeholder {
              background: #000;
              opacity: 0.1;
              transition-duration: 100ms;
              z-index: 2;
              border-radius: 4px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              -o-user-select: none;
              user-select: none;
              max-width: 100%;
            }
          }
        } 
        .react-grid-item {
          transition: all 200ms ease;
          transition-property: left, top;
        }
        .react-resizable-handle {
          display: none;
        }
        .react-grid-item.cssTransforms {
          transition-property: transform;
        }
        .react-grid-item.react-draggable-dragging {
          transition: none;
          z-index: 3;
        }
        .react-grid-item.react-grid-placeholder {
          background: #000;
          opacity: 0.1;
          transition-duration: 100ms;
          z-index: 2;
          border-radius: 4px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
          max-width: 100%;
        }
        .report-select-btn {
          position: relative;
          background-color: transparent;
          border: none;
          width: 100%;
          max-width: 95%;
          min-height: 38px;
          color: var(--bs-gray-2);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          line-height: normal;
          &:hover, &:active, &:focus, &.active {
            color: var(--bs-secondary);
            font-weight: 600;
          }
          &:active, &:focus, &.active  {
            background-color: var(--bs-secondary-6);
            box-shadow: none;
          }
          .report-select-btn-name {
            text-align: left;
            display: flex;
            align-items: center;
          }
          svg {
            flex: none;
          }
        }
      }
      .report-order-btns {
        margin-top: 1rem;
        display: flex;
        button {
          width: 100%;
          height: 2rem;
          font-size: 0.875rem;
          font-weight: 600;
          + button {
            margin-left: 1rem;
          }
        }
      }
      .grid-name-container {
        padding: 0.2rem;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.5rem;
        }
      }
      .report-ordering-icon, .section-ordering-icon {
        cursor: grab;
        &:hover {
          color: var(--bs-secondary);
        }
      }
    }
    .right-container {
      width: 55%;
      display: flex;
      flex-direction: column;
      .report-form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        font-size: 0.875rem;
        font-weight: 500;
        .form-group {
          height: 2rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .report-form-label {
            color: var(--bs-gray-4);
            font-weight: 600;
          }
          .report-form-value {
            padding: 0.15rem 0.5rem;
          }
          .report-name-input {
            min-width: 15rem;
            width: 50%;
            text-align: end;
            padding: 0.15rem 0.5rem;
          }
          .report-icon-toggle {
            color: var(--bs-secondary);
            fill: currentColor;
            padding: 0.25rem;
            height: fit-content;
            svg {
              height: 24px;
              width: 24px;
            }
            &:hover {
              background-color: transparent;
              border-color: var(--bs-secondary);
            }
          }
          .dropdown-menu.show {
            display: flex;
          }
          .report-icon-select {
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 0.1rem;
            padding: 0.25rem;
            background-color: hsla(0, 0%, 83%, 0.75);
            box-shadow: 0.2rem 0.2rem 0.25rem lightgray;
            .report-icon {
              color: var(--bs-secondary);
              padding: 0.2rem;
              border-radius: 0.2rem;
              height: 30px;
              width: 30px;
              fill: currentColor;
              border-width: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        #report-icons-container {
          height: fit-content;
          align-items: flex-start;
          margin-top: 0.5rem;
        }
        .edit-report-btn {
          font-size: 0.75rem;
          width: 10rem;
          height: 2.5rem;
          margin-top: 3rem;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #adminReportSettings {
    padding-top: 0.5rem;
    .report-content {
      flex-direction: column;
      gap: 1.5rem;
      .left-container {
        width: 100%;
        height: fit-content;
        overflow-y: visible;
      }
      .right-container {
        width: 100%;
        .report-form {
          align-items: center;
          .form-group .report-name-input {
            min-width: 12.5rem;
          }
          .edit-report-btn {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #adminReportSettings .report-content .right-container .report-form {
    .form-group {
      align-items: flex-start;
      flex-direction: column;
      gap: 0.2rem;
      height: auto;
      .report-form-value {
        width: 100%;
        text-align: end;
      }
      .report-name-input {
        width: 100%;
      }
      .report-icon-select {
        width: 100%;
        padding: 0 4%;
      }
    }
  }
}