#addClientModal {
  max-width: none;
  width: 60rem;
  .modal-content {
    border-radius: 0;
    padding: 2.5rem;
  }
  .modal-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--bs-secondary);
  }
  .new-client-fields {
    margin: 1.5rem 0;
  }
  .form-subtitle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    div {
      font-size: 0.75rem;
      color: var(--bs-gray-2);
      font-weight: 600;
      display: flex;
      align-items: center;
      input {
        margin-right: 0.5rem;
        height: 1rem;
        width: 2rem;
        cursor: pointer;
      }
    }
  }
  .form-subtitle {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--bs-gray-2);
  }
  .form-label {
    margin: 0;
  }
  .form-button {
    margin-top: 3rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 1.5rem;
      width: 20%;
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 900px) {
  #addClientModal {
    width: 95%;
    margin: 0;
    position: absolute;
    left: 2.5%;
    right: 2.5%;
    top: 2%;
    bottom: 2%;
    .modal-content {
      padding: 1rem;
    }
    .modal-title {
      font-size: 1rem;
    }
    .form-subtitle-container {
      padding-right: 0; 
      div {
        font-size: 0.7rem;
      }
    }
    .form-button {
      margin: 1.5rem 0;
      justify-content: center;
      button {
        padding: 1.5rem 0;
        width: 10rem;
      }
    }
  }
}

@media (max-width: 480px) {
  #addClientModal {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}