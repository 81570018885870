@import './ADGroup/ADGroup.scss';
@import './EditGroup/EditGroup.scss';
@import './CreateGroup/CreateGroup.scss';
@import './GroupMembersModal/GroupMembersModal.scss';

#groupSettings {
  padding-top: 1rem;
  .group-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .group-content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    .left-container {
      flex-basis: 37.5%;
      margin-right: 2.5rem;
    }
    .right-container {
      flex-basis: 62.5%;
    }
    .search-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      .sync-btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
        .add-group-btn {
          padding: 1.2rem;
          width: 80%;
          font-weight: 500;
        }
      }
      .last-sync {
        width: 75%; 
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--bs-gray-2);
        margin: 0.4rem auto;
        text-align: center;
        font-style: italic;
      }
      .auto-sync-container {
        margin: 0.5rem 0.75rem;
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
    .group-select-container {
      padding-top: 18px;
      display: flex;
      flex-direction: column;
    }
    .group-select {
      position: relative;
      display: flex;
      align-items: center;
    }
    .group-select-btn {
      background-color: transparent;
      border: none;
      width: 100%;
      min-height: 38px;
      color: var(--bs-gray-2);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: normal;
      &:hover,
      &:active,
      &:focus,
      &.active {
        color: var(--bs-secondary);
        font-weight: 600;
      }
      &:active,
      &:focus,
      &.active {
        background-color: var(--bs-secondary-6);
        box-shadow: none;
      }
      .group-select-btn-name {
        text-align: left;
      }
      svg {
        flex: none;
      }
    }
    .group-delete-btn {
      width: fit-content;
      position: absolute;
      right: 1rem;
      padding: 0;
      background-color: transparent;
      border: none;
      color: var(--bs-gray-2);
      &:hover {
        color: var(--bs-primary);
      }
    }
  }
}

#new-group-modal {
  max-width: none;
  width: 50rem;
  .modal-content {
    border-radius: 0;
    .modal-header {
      border: none;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 8rem 5rem 8rem;
      .group-modal-title {
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--bs-secondary);
        text-align: center;
        margin: 2.5rem 0;
      }
      .group-modal-selected {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: 600;
        font-size: 0.875rem;
        margin-top: 3rem;
        .selected-group-label {
          color: var(--bs-gray-4);
        }
        .selected-group-name {
          color: var(--bs-secondary);
          font-size: 1rem;
        }
      }
      .group-modal-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        .group-modal-btn {
          height: 2.25rem;
          width: 9rem;
          border-radius: 0;
          font-size: 0.75rem;
          font-weight: 700;
          +.group-modal-btn {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  #groupSettings .group-content .search-container {
    .sync-btn-container {
      padding: 0 0.5rem;
      .add-group-btn {
        font-size: 1rem;
        width: 85%;
        padding: 1.2rem 0.25rem;
        margin-left: 0.5rem;
        
      }
    }
    .last-sync {
      width: 80%;
    }
  }
}

@media (max-width: 900px) {
  #groupSettings {
    padding-top:0.5rem;
    .group-content {
      flex-direction: column;
      .left-container {
        margin: 0;
        flex-basis: 1;
      }
      .right-container {
        flex-basis: 1;
        .edit-group-container {
          padding: 0 0.5rem;
        }
        .edit-header {
          .edit-header-members {
            width: 100%;
            font-size: 1rem;
            padding: 1.2rem 0.75rem;
          }
          .edit-header-value {
            margin-left: 0.5rem;
          }
        }
      }
      .search-container {
        .sync-btn-container {
          padding: 0 0.5rem;
          .add-group-btn {
            font-size: 1rem;
            width: 85%;
            padding: 1.2rem 0.75rem;
            margin-left: 0.5rem;
            
          }
        }
        .last-sync {
          width: 90%;
        }
      }
      .group-select-container {
        margin-bottom: 1rem;
      }
      .group-select {
        .group-select-btn {
          padding: 0.5rem;
        }
        .group-delete-btn {
          right: 0.75rem;
        }
      }
    }
  }
  #new-group-modal {
    width: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    .modal-content {
      border-radius: 0;
      .modal-header {
        border: none;
      }
      .modal-body {
        padding: 2rem;
        .group-modal-title {
          font-size: 1rem;
          font-weight: 600;
          margin: 0 0 2.5rem 0;
        }
        .group-modal-selected {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          font-weight: 600;
          font-size: 0.875rem;
          margin-top: 3rem;
          .selected-group-label {
            color: var(--bs-gray-4);
          }
          .selected-group-name {
            color: var(--bs-secondary);
            font-size: 1rem;
          }
        }
        .search-box {
          margin: auto;
          width: 80%;
          min-width: 25rem;
        }
        .group-modal-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3rem;
          .group-modal-btn {
            height: 2.25rem;
            width: 9rem;
            border-radius: 0;
            font-size: 0.75rem;
            font-weight: 700;
            +.group-modal-btn {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #groupSettings .group-content .search-container .sync-btn-container .add-group-btn {
    font-size: 0.875rem;
    width: 95%;
    padding: 1.2rem 0.5rem;
    margin-left: 0.5rem;  
  }
  #new-group-modal .modal-content .modal-body .search-box {
    width: 100%;
    min-width: 0;
  }
}