#workspaceSettings {
  padding-top: 1rem;
  .workspaces-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .workspace-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    .left-container {
      width: 35%;
      display: flex;
      flex-direction: column;
    }
    .right-container {
      width: 60%;
      display: flex;
      flex-direction: column;
    }
    .title-label {
      font-size: 0.875rem;
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: var(--bs-gray-2);
      border-bottom: 1px solid var(--bs-gray-2);
    }
    .workspace-select-btn {
      position: relative;
      background-color: transparent;
      border: none;
      width: 100%;
      min-height: 38px;
      color: var(--bs-gray-2);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: normal;
      &:hover, &:active, &:focus, &.active {
        color: var(--bs-secondary);
        font-weight: 600;
      }
      &:active, &:focus, &.active  {
        background-color: var(--bs-secondary-6);
        box-shadow: none;
      }
      .workspace-select-btn-name {
        text-align: left;
        display: flex;
        align-items: center;
      }
      svg {
        flex: none;
      }
    }
    .workspace-new-btn {
      font-size: 0.875rem;
      margin: 1rem 0;
      padding: 1rem;
    }
    .edit-workspace-btn {
      font-size: 0.75rem;
      width: 10rem;
      height: 2.5rem;
      +.edit-workspace-btn {
        margin-left: 1rem;
      }
    }
    .edit-workspace-form {
      padding: 0 0.5rem;
    }
    .sync-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding-left: 1rem;
      .last-sync {
        width: 15rem; 
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--bs-gray-2);
        text-align: center;
        font-style: italic;
      }
    }
    .form-group {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
    }
    .edit-workspace-label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      font-weight: 600;
      padding: 0 0.25rem;
    }
    .edit-workspace-input {
      width: 65%;
      padding: 0 0.5rem;
      border: none;
      border-bottom: 1px solid transparent;
      text-align: end;
      &:focus {
        outline: none;
        border-bottom: 1px solid var(--bs-secondary-2);
      }
    }
    .search-container {
      font-weight: 600;
      font-size: 0.875rem; 
      margin: 0.5rem 0;
      padding: 0 0.25rem;
    }
    .form-clients-list {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
    }
  }
  #clients-label {
    padding: 0 0.25rem;
    padding-bottom: 0.5rem;
  }
  .no-clients {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .workspace-clients {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0.25rem 0.5rem;
  }
  .workspace-client {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.25rem;
    +.workspace-client {
      border-top: 1px solid var(--bs-gray-2);
      margin-top: 0.2rem;
      padding-top: 0.2rem;
    }
  }
  .workspace-client-name {
    font-size: 0.875rem;
    font-size: 550;
  }
  .remove-client-btn {
    margin-right: 0.4rem;
    padding: 0 0.75rem;
  }
  .form-buttons {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}

@media (max-width: 900px) {
  #workspaceSettings {
    padding-top: 0.5rem;
    .workspace-content {
      flex-direction: column;
      margin-top: 1.5rem;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 100%;
        margin-top: 1rem;
      }
      .sync-btn-container {
        padding: 0 0.5rem;
        margin-bottom: 1rem;
        .last-sync {
          width: 12rem; 
          font-size: 0.6rem;
        }
      }
      .form-group {
        margin: 0;
        margin-top: 0.8rem;
      }
      .edit-workspace-label {
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.8rem;
        padding: 0 0.25rem;
      }
      .edit-workspace-input {
        width: 100%;
        padding: 0.125rem 0.25rem;
        margin-top: 0.25rem;
      }
      .search-container {
        margin: 0.5rem 0;
        padding: 0;
      }
      .form-clients-list {
        margin: 1rem 0;
      }
    }
    #clients-label {
      padding: 0 0.25rem;
      padding-bottom: 0.5rem;
    }
    .form-buttons {
      margin-top: 5rem;
      padding-bottom: 2rem;
    }
  }
}