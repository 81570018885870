#clientResources {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  .resources-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    padding-right: 2.5rem;
    .dw-refresh-btn {
      font-size: 0.75rem;
      font-weight: 700;
      margin-left: 2.5rem;
    }
    .title-status-labels {
      width: 11rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 0.6rem;
        font-weight: 600;
        width: 3.25rem;
        text-align: center;
      }
    }
  }
  .dw-loader {
    height: 5rem;
    width: 5rem;
    margin-top: 2.5rem;
  }
  .client-dw {
    width: 100%;
    color: var(--bs-gray-2);
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    .dw-acc-header {
      width: 100%;
      .client-dw-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .client-dw-title {
          .client-dw-name {
            width: fit-content;
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
            height: fit-content;
            color: var(--bs-body-color);
          }
          .client-dw-code {
            font-size: 0.9rem;
            font-weight: 600;
            color: var(--bs-gray-2);
            margin-left: 0.5rem;
          }
        }
        .client-header-status {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .dw-status-container {
      width: 4rem;
      font-size: 0.6rem;
      font-weight: 600;
      color: var(--bs-gray-2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      span {
        width: 95%;
        text-align: center;
        text-overflow: ellipsis; 
      }
      .dw-status {
        border-radius: 50%;
      }
      #dw-stopped {
        color: var(--bs-danger);
      }
      #dw-started {
        color: var(--bs-success);
      }
      #dw-error {
        color: var(--bs-gray-4);
      }
      #dw-updating {
        color: var(--bs-warning);
      }
      #dw-onprem {
        color: var(--bs-info);
      }
    }
    .dw-acc-body {
      border: solid var(--bs-gray-3);
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 0.25rem 0.25rem;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 2rem;
      .dw-body-links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .dw-links {
          padding: 0.375rem 0.75rem;
          text-decoration: none;
          color: var(--bs-body-bg);
          background-color: var(--bs-secondary);
          font-size: 0.75rem;
          font-weight: 600;
          border-radius: 0.25rem;
          &:hover {
            filter: brightness(1.2);
          }
        }
      }
      .dw-body-statuses {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dw-body-status {
          display: flex;
          flex-direction: column;
          align-items: center;
          .dw-resource-title {
            color: var(--bs-body-color);
            font-size: 0.8rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }
          .dw-status-buttons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            #dw-action-btn {
              margin-left: 0.5rem;
              margin-right: 1rem;
            }
            #dw-refresh-btn {
              border-radius: 50%;
              height: 1.75rem;
              width: 1.75rem;
              padding: 0;
            }
          }
        }
      }
      button {
        display: flex;
        justify-content: center;
        font-size: 0.75rem;
        font-weight: 600;
        align-items: center;
        &:disabled {
          color: var(--bs-body-color);
        }
      }    
    }
  }
}

@media (max-width: 900px) {
  #clientResources {
    padding-top: 0.5rem;
    .title-container {
      padding-right: 0.75rem;
      .dw-refresh-btn {
        margin-left: 0.25rem;
      }
      .title-status-labels {
        width: 11rem;
        justify-content: space-between;
        span {
          width: 3rem;
        }
      }
    }
    .dw-loader {
      height: 5rem;
      width: 5rem;
      margin-top: 2.5rem;
    }
    .client-dw {
      padding: 0;
      .dw-acc-header {
        width: 100%;
        .accordion-button {
          padding: 1rem 0.25rem;
          padding-left: 0.5rem;
          .client-dw-header {
            .client-dw-title {
              display: flex;
              flex-direction: column; 
              align-items: center;
              .client-dw-name {
                font-size: 0.875rem;
              }
              .client-dw-code {
                font-size: 0.75rem;
                margin-left: 0;
              }
            }
            .client-header-status {
              width: 12rem;
            }
          }
        }
      }
      .dw-status-container {
        width: 33%; 
      }
      .dw-acc-body {
        padding: 0.5rem 0.25rem;
        .dw-body-links {
          padding: 0.5rem;
          flex-flow: row wrap;
          .dw-links {
            margin-bottom: 0.25rem;
          }
        }
        .dw-body-statuses {
          margin-top: 0.5rem;
          padding: 0.5rem 1rem;
          flex-direction: column;
          .dw-body-status {
            width: 100%;
            text-align: center;
            .dw-resource-title {
              width: 100%;
            }
            .dw-status-buttons {
              width: 80%;
              .dw-status-container {
                width: 4rem; 
                font-size: 0.55rem;
              }
              #dw-action-btn {
                width: 6rem;
                margin: 0 0.25rem;
              }
              #dw-refresh-btn {
                margin: 0 0.25rem;
              }
            }
            +.dw-body-status {
              margin-top: 0.75rem;
            }
          }
        }  
      }
    }
  }
}

@media (max-width: 480px) {
  #clientResources {
    .title-container {
      padding-right: 0.5rem;
      .title-status-labels {
        width: 62.5%;
        justify-content: space-around;
      }
    }
    .client-dw {
      .dw-acc-header .accordion-button .client-dw-header .client-header-status {
        width: 70%;
      }
      .dw-status-container {
        width: 33%; 
        font-size: 0.55rem;
      }
    }
  }
}