.dark {
  @import "bootstrap/scss/functions";
  @import './variables.scss';

  --bs-body-color: #{$body-color};
  --bs-body-bg: #{$body-bg};
  --bs-hover-bg: #{$hover-bg};
  --bs-box-shadow-color: #{$box-shadow-color};
  
  @each $color, $value in $theme-colors {
    --bs-#{$color}: #{$value};
  }

  /* Components */
  @import './accordion.scss';
  @import './tabs.scss';

  @import "bootstrap/scss/bootstrap";

  /* Dropdown toggle focus and active styling */
  @each $color, $value in $theme-colors {
    .show>.btn-#{$color}.dropdown-toggle  {
      @include button-variant($value, $value);
    }
  }
}
