#userSettings .edit-user-container {
  #support-role-span {
    font-size: 0.7rem;
    margin: 0.5rem;
  }
  .edit-header {
    font-size: 0.875rem;
    font-weight: 500;
    p {
      margin: 0;
    }
    +.edit-header {
      margin-top: 1rem;
    }
    .edit-header-title {
      margin-bottom: 0.5rem;
      color: var(--bs-gray-4);
      font-weight: 600;
    }
  }
  .user-role-radio {
    display: flex;
    align-items: center;
    .form-check {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      .form-check-input {
        background-color: var(--bs-gray-3);
        width: 0.75rem;
        height: 0.75rem;
        margin: 0;
        cursor: pointer;
        &:checked {
          background-color: var(--bs-tertiary);
          background-image: none;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .form-check-label {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--bs-gray-2);
        margin-left: 0.75rem;
      }
      +.form-check {
        margin-left: 2rem;
      }
    }
  }
}

@media (max-width: 480px) {
  #userSettings .edit-user-container {
    .edit-header .edit-header-value {
      margin-left: 0.5rem;
    }
    .user-role-radio {
      justify-content: space-around;
      .form-check {
        padding: 0;
        .form-check-label {
          font-size: 0.75rem;
          margin-left: 0.3rem;
        }
        +.form-check {
          margin-left: 0.2rem;
        }
      }
    }
    #support-role-span {
      display: flex;
      width: 90%;
      font-size: 0.65rem;
    }
  }
}