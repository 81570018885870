#embedPage {
  #embedLoader {
    padding-top: 200px;
  }
  &.is-loading #embedComponent {
    visibility: hidden;
  }
  &.is-not-loading #embedLoader {
    display: none;
  }
}