@import './IdleModal/idleModal.scss';

#reportPage {
  height: 100%;
  #mobile-hamburger-container {
    display: none;
  }
  .mobile-menu-background {
    display: none;
  }
  .report-style-class {
    box-sizing: border-box;
    height: 100vh;
  }
  .embed-error-message {
    text-align: center;
    color: var(--bs-secondary);
    padding-top: 200px;
    font-weight: 600;
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
  }
  .reportEmbed {
    height: 100%;
    #embedPage {
      height: 100%;
      #embedComponent {
        height: 100%;
        .report-style-class {
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #reportPage {
    position: relative;
    padding-right: 4px;
    #mobile-hamburger-container {
      width: 100vw;
      z-index: 99;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      top: -16px;
      transition: height 250ms;
      left: -16px;
      right: -16px;
      background-color: var(--bs-primary);
      #mobile-hamburger {
        margin-right: 1rem;
        border: none;
        background-color: transparent;
        color: var(--bs-body-bg);
      }
    }
    #mobile-hamburger-container.no-menu {
      height: 36px;
    }
    #mobile-hamburger-container.menu {
      height: 108px;
    }
    .mobile-menu-background {
      display: flex;
      position: absolute;
      top: 0;
      height: calc(100vh + 108px);
      left: -16px;
      right: -16px;
      background-color: rgba(128, 128, 128, 0.314);
    }
    .report-style-class {
      height: 90vh;
    }
    .reportEmbed {
      height: calc(100% - 40px);
    }
  } 
}

@media (max-width: 480px) {
  #reportPage {
    margin-top: 0.5rem;
    #mobile-hamburger-container {
      left: -8px;
      right: -8px;
    }
    #mobile-hamburger-container.no-menu {
      height: 24px;
    }
    #mobile-hamburger-container.menu {
      height: 96px;
    }
    .mobile-menu-background {
      height: calc(100vh + 96px);
      left: -8px;
      right: -8px;
    }
    .reportEmbed {
      height: calc(100% - 30px);
    }
  }
}