.search-box {
  position: relative;
  margin-top: 0.5rem;
  .search-box-input {
    position: relative;
    input {
      border-radius: 0.15rem;
      border: 1px solid black;
      padding: 0.5rem 2.5rem 0.5rem 1.5rem;
      font-size: 0.875rem;
    }
    .search-icon {
      color: var(--bs-gray-2);
      position: absolute;
      right: 1rem;
      top: 0.65rem;
    }
  }
  .search-box-result-container {
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    width: 100%;
    height: 300px;
    .list-item {
      width: 100%;
      white-space: normal;
      height: auto;
      line-height: normal;
      overflow: hidden;
      overflow-wrap: break-word;
    }
    .items-loading-container {
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 480px) {
  .search-box {
    position: relative;
    margin-top: 0.5rem;
    .search-box-input {
      input {
        padding-left: 0.75rem;
        padding-right: 2rem;
        font-size: 0.875rem;
      }
      .search-icon {
        color: var(--bs-gray-2);
        position: absolute;
        right: 0.6rem;
        top: 0.65rem;
      }
    }
    .search-box-result-container {
      position: absolute;
      z-index: 1;
      overflow-y: auto;
      width: 100%;
      height: 300px;
      .list-item {
        width: 100%;
        white-space: normal;
        height: auto;
        line-height: normal;
        overflow: hidden;
        overflow-wrap: break-word;
      }
      .items-loading-container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}