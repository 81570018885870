#topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 27px;
  padding-left: 28px;
  padding-right: 33px;
  .topbar-signout {
    width: 6.25rem;
    font-size: 0.625rem;
    height: 1.5rem;
  }
  .topbar-mobile-hamburger {
    display: none;
  }
}

@media (max-width: 900px) {
  #topbar {
    padding: 0 1rem;
    margin: 2rem 0 1rem 0;
    .topbar-signout {
      display: none;
    }
    .topbar-mobile-hamburger {
      display: flex;
      border: none;
      background-color: transparent;
      padding: 0;
      color: var(--bs-secondary);
      z-index: 99;
    }
  }
}

@media (max-width: 480px) {
  #topbar {
    padding: 0 0.75rem;
    margin: 1.5rem 0 0.5rem 0;
  }
}