@import './EditUser/EditUser.scss';

#userSettings {
  padding-top: 1rem;
  .user-title {
    color: var(--bs-secondary);
    font-size: 1.25rem;
    font-weight: 600;
  }
  .user-content {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .left-container {
    flex-basis: 35%;
  }
  .right-container {
    flex-basis: 55%;
  }
}

@media (max-width: 1400px) {
  #userSettings .left-container {
    flex-basis: 40%;
  }
}

@media (max-width: 900px) {
  #userSettings {
    padding-top: 0.5rem;
    .user-content {
      flex-direction: column;
    }
    .left-container {
      flex-basis: 1;
      margin-bottom: 2rem;
    }
    .right-container {
      flex-basis: 1;
    }
  }
}