#settingsPage {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--bs-body-bg-light);
  .container {
    width: 100%;
    margin: 2.5rem 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: none;
    > *:nth-child(n) {
      width: 100%;
    }
    .tab-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 0.9rem;
      color: var(--bs-gray-2);
      div {
        margin-left: 0.5rem;
      }
    }
    .tab-content {
      padding: 1rem 3rem;
    }
  }
  .nav-tabs, .nav-tabs .nav-item.show .nav-link, .nav-link {
    border: none;
    --bs-nav-tabs-border-width: none;
  }
  .nav-link.active {
    background-color: var(--bs-body-bg-light);
    text-decoration: 2px underline var(--bs-secondary-2);
    text-underline-offset: 13px;
    transition: 0ms;
    .tab-title {
      color: var(--bs-secondary-2);
      font-weight: 600;
    }
  }
  .nav {
    padding: 0 2rem;
    border-bottom: 1px solid var(--bs-gray-3);
    margin-bottom: 1rem;
  }
}

@media (max-width: 1400px) {
  #settingsPage {
    .container {
      width: 100%;
      margin: 0 1rem;
      padding: 0;
      .tab-content {
        padding: 1rem 1.5rem;
      }
    }
    .nav {
      padding: 0 1rem;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  #settingsPage {
    .container {
      margin: 0;
      padding: 5%;
      .tab-title {
        width: 100%;
        div {
          margin-left: 0.5rem;
          display: none;
          white-space: nowrap;
          width: 100%;
        }
      }
      .tab-content {
        padding: 1rem;
        padding-bottom: 2.5rem;
      }
    }
    .nav-link.active .tab-title div {
      display: flex;
    }
    .nav {
      padding: 0;
    }
  }
}

@media (max-width: 480px) {
  #settingsPage {
    .container {
      padding: 0;
    }
    .nav {
      flex-wrap: nowrap;
      overflow: scroll hidden;
      scrollbar-width: thin;
      scrollbar-color: rgba(131, 131, 131, 0.428) transparent;
    }
  }
}