.search-box {
  .search-box-result-container {
    border: 2px solid $gray-3;
    border-radius: 4px;
    background-color: $body-bg;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .search-box-result-container button {
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-color: $gray-2;
    border-radius: 0;
  }
}
