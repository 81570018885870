#rls-roles-settings {
  position: relative;
  display: flex;
  flex-direction: column;
  .rls-title {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .roles-titles {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 4fr 3fr;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
  }
  .rls-roles-grid {
    #rls-role {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .grid-name-container {
        width: fit-content;
        .role-ordering-icon {
          cursor: pointer;
        }
        input {
          height: 2rem;
          width: 10rem;
        }
      }
      #rls-role-guardians {
        margin-left: 0.75rem;
      }
      .role-group-select {
        height: 2rem;
        width: 10rem;
        overflow-x: hidden;
        display: flex;
        align-items: center;
      }
      .role-buttons {
        display: flex;
        align-items: center;
        input {
          margin-right: 1rem;
        }
      }
    }
    #rls-role.deleted {
      opacity: 0.5;
      transition: opacity 0.3s ease;
    }
  }
  .new-rls-role {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label input {
      margin-left: 1rem;
    }
  }
}