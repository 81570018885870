.client-powerbi-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  .form-switch {
    width: fit-content;
    padding: 0;
    margin-right: 0.25rem;
  }
  .client-powerbi-label {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.25rem;
    width: fit-content;
    margin: 0;
  }
}
.client-powerbi-group {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  +.client-powerbi-group {
    margin-top: 0.5rem;
  }
  .client-powerbi-label {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.25rem;
    width: fit-content;
    margin: 0;
  }
  .client-powerbi-input {
    font-size: 0.875rem;
    font-weight: 300;
    width: 30rem;
    text-align: end;
    border-bottom: 1px solid var(--bs-secondary);
    resize: none;
    white-space: normal;
    word-break: break-all;
  }
}
#app-secret-warn {
  width: fit-content;
  font-size: 0.7rem;
  color: var(--bs-danger);
  margin: 0.5rem 0.75rem;
}

@media (max-width: 900px) {
  .client-powerbi-switch {
    padding: 0 0.25rem;
    .form-switch .form-check-input {
      margin-left: 0;
    }
  }
  .client-powerbi-group {
    min-width: 20rem;
    padding: 0 0.25rem;
    .client-powerbi-input {
      width: 70%;
      padding: 5px;
    }
  }
  #app-secret-warn {
    margin: 0.5rem;
  }
}

@media (max-width: 480px) {
  .client-powerbi-switch {
    padding: 0;
    .form-switch .form-check-input {
      margin-right: 1rem;
    }
  }
  .client-powerbi-group {
    min-width: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    .client-powerbi-input {
      width: 98%;
      margin: auto;
    }
  }
  #app-secret-warn {
    margin: 0.5rem 0.25rem;
  }
}
