.tile-outer-container {
  border-radius: 0.5rem;
  .report-tile-container {
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
    width: 21rem;
    background-color: var(--bs-body-bg-light);
    padding: 1.65rem 2rem;
    cursor: pointer;
    .report-header {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      font-size: 1.25rem;
      color: var(--bs-secondary);
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 0.1rem;
      .report-icon {
        width: 1.875rem;
        height: 1.875rem;
        fill: currentColor;
      }
      .report-name {
        text-overflow: ellipsis;
        width: 85%;
      }
    }
  }
  &:hover {
    box-shadow: 0 0 0.5rem 0.05rem var(--bs-gray-3);
  }
}

@media (max-width: 1400px) {
  .tile-outer-container {
    min-width: 20rem;
    .report-tile-container {
      width: 100%;
      padding: 1.5rem 1.875rem;
      .report-header {
        width: 100%;
        gap: 1.25rem;
        .report-icon {
          width: 1.65rem;
          min-width: 1.65rem;
          height: 1.65rem;
          min-height: 1.65rem;
        }
        .report-name {
          font-size: 1.2rem;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .tile-outer-container {
    min-width: 0;
    .report-tile-container {
      width: 100%;
      min-width: 0;
      padding: 1.75rem;
      .report-header {
        gap: 1rem;
        .report-name {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .tile-outer-container .report-tile-container {
    padding: 1.25rem;
    .report-header {
      .report-icon {
        width: 1.4rem;
        min-width: 1.4rem;
        height: 1.4rem;
        min-height: 1.4rem;
      }
      .report-name {
        font-size: 1rem;
      }
    }
  }
}