@import './scss/index.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-image: url('./assets/bg-water-logo.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal {
  display: flex;
  padding-left: var(--sidebar-width-collapsed);
  justify-content: center;
  .modal-dialog {
    width: 500px;
  }
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(131, 131, 131, 0.428);
}

@media (max-width: 900px) {
  body {
    padding-right: 0 !important;
  }
  .modal {
    padding-left: 0;
    .modal-dialog {
      width: auto;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
}