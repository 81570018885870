#select-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  .select-client-btn {
    padding: 1rem;
    font-size: 0.875rem;
    width: 90%;
    +.select-client-btn {
      margin-top: 0.25rem;
    }
  }
}