.loader-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .spinner-border {
    position: relative;
    border: none !important;
    width: 11rem;
    height: 11rem;
    background: linear-gradient(var(--bs-secondary-2), var(--bs-body-bg));
    vertical-align: -.125em;
    animation: 0.75s linear infinite spinner-border;
    &::before {
      position: absolute;
      content: "";
      background: var(--bs-body-bg);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      border-radius: 10rem;
      border: none;
    }
  }
  .message {
    color: var(--bs-secondary);
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
  }
}