.nav-tabs {
  .nav-item .nav-link {
    font-weight: 600;
    background-color: $body-bg-light;
    color: $gray-4;
    border-color: $gray-4;
    border-width: 1px 1px 0px 1px;
    &:hover {
      border-color: $secondary;
      color: $secondary;
    }
  }
  .nav-item .nav-link.active {
    background-color: $secondary;
    color: $body-color-inv;
  }
}