#groupSettings, .edit-group-container {
  .edit-header {
    font-size: 0.875rem;
    font-weight: 500;
    p {
      margin: 0;
    }
    +.edit-header {
      margin-top: 1rem;
    }
    .edit-header-title {
      margin-bottom: 0.5rem;
      color: var(--bs-gray-4);
      font-weight: 600;
    }
    .edit-header-members {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 3.25rem;
      span {
        margin-left: 0.5rem;
        font-weight: 500;
      }
    }
  }
  .form-switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    input {
      cursor: pointer;
    }
  }
  .text-input-title h5 {
    font-size: 1rem;
  }
  .manage-reports .form-label.title {
    width: 100%;
    border-bottom: 1px solid var(--bs-secondary);
    p {
      color: var(--bs-secondary);
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
  .select-options {
    max-height: 30vh;
    min-height: 75px;
    overflow-y: scroll;
    scrollbar-width: thin; 
    scrollbar-color: rgba(131, 131, 131, 0.428) transparent;
    padding: 0 0.5rem;
    div {
      padding: 0.5rem 0;
      .report-option-name {
        position: relative;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 550;
        color: var(--bs-gray-2);
      }
      .report-rls-icon {
        position: absolute;
        color: var(--bs-secondary);
        left: -0.5rem;
      }
      .enabled {
        color: var(--bs-body-color);
      }
      input {
        height: 1.2rem;
        width: 2.5rem;
      }
      +div {
        border-top: 1px solid var(--bs-gray-3);
      }
    }
  }
}
