.btn.btn-primary {
  background-color: $secondary;
  line-height: 0;
  border-radius: 2px;
  font-weight: 600;
  border-width: 0;
  height: 30px;
  &:hover {
    background-color: $secondary-2;
  }
  &:disabled {
    background-color: $gray-2;
  }
}

.btn.btn-secondary {
  background-color: transparent;
  border: 1px solid $secondary-2;
  line-height: 0;
  border-radius: 4px;
  font-weight: 600;
  height: 30px;
  color: $secondary-2;
  &:hover {
    background-color: $secondary;
    color: $body-color-inv;
  }
  &:disabled {
    background-color: $gray-2;
    color: $gray-2;
  }
}
